var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"query_record"},[(_vm.recordbool)?_c('div',[_c('div',{staticClass:"main_image"}),_c('span',[_vm._v(_vm._s(_vm.getWord('no_result6')))]),_c('span',[_vm._v(_vm._s(_vm.getWord('start_game2')))])]):_vm._e(),(! _vm.recordbool)?_c('div',{ref:"tradeHistory",attrs:{"id":"trade-history"}},[_c('table',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMore_data),expression:"loadMore_data"}],staticClass:"main_table",attrs:{"cellspacing":"0","border":"0","infinite-scroll-disabled":"loading_data2","infinite-scroll-distance":"50"}},[_c('tr',[_c('th',{attrs:{"width":"15%"}},[_vm._v(_vm._s(_vm.getWord('drawing_number')))]),_c('th',{attrs:{"width":"55%"}},[_vm._v(_vm._s(_vm.getWord('content')))]),_c('th',{attrs:{"width":"15%"}},[_vm._v(_vm._s(_vm.getWord('amount_of_betting')))]),_c('th',{attrs:{"width":"15%"}},[_vm._v(_vm._s(_vm.getWord('result')))])]),(_vm.list.length===0)?_c('tr',[_c('td',{staticClass:"tip",attrs:{"colspan":"4"}},[_vm._v(_vm._s(_vm.getWord('no_result6')))])]):_vm._e(),_vm._l((_vm.list),function(item,i){return _c('tr',{key:i},[_c('td',[_vm._v(" "+_vm._s(item.issue)+" ")]),(item.lottery_class == 'WEI_LIAN'
                 || item.lottery_class == 'ALLNOTIN' 
                 || item.lottery_class == 'LIANMA' 
                 || item.lottery_class == 'ANIMAL_LIAN' 
                 || item.lottery_class == 'TEMA_HE_ANIMAL' 
                 || item.lottery_class == 'QIANER' 
                 || item.lottery_class == 'QIANSAN' 
                 || item.lottery_class == 'ZUER' 
                 || item.lottery_class == 'ZUSAN' 
                 || item.lottery_class.indexOf('LIANMA')>-1 
                 || item.lottery_class == 'ERZIBAISHIWEI' 
                 || item.lottery_class == 'ERZIBAIGEWEI' 
                 || item.lottery_class == 'ERZISHIGEWEI' 
                 || item.lottery_key.indexOf('LIAN_REN_')>-1  
                 || item.lottery_class.indexOf('ZUXUANSAN')>-1 
                 || item.lottery_class.indexOf('ZUXUANLIU')>-1)?_c('td',[_c('span',[_vm._v(_vm._s(item.bet_name))]),_c('span',{staticClass:"number"},[_vm._v(_vm._s(item.bet_number))]),_c('span',{staticClass:"date"},[_vm._v(_vm._s(new Date(item.created_at*1000).toLocaleString().split(' ')[0])+" "+_vm._s(new Date(item.created_at*1000).toTimeString().split(' ')[0]))])]):_c('td',[_c('span',[_vm._v(_vm._s(item.bet_name))]),_c('span',{staticClass:"number"},[_vm._v(_vm._s(isNaN(item.bet_number)?'':item.bet_number))]),_c('span',{staticClass:"date"},[_vm._v(_vm._s(new Date(item.created_at*1000).toLocaleString().split(' ')[0])+" "+_vm._s(new Date(item.created_at*1000).toTimeString().split(' ')[0]))])]),_c('td',[_vm._v(_vm._s(item.money.toFixed(2)))]),_c('td',[(item.status==1)?_c('span',{staticStyle:{"color":"rgba(102,102,102,1)"}},[_vm._v(_vm._s(_vm.getWord('unsettled_account')))]):(item.status==2&&item.bonus_money=='0')?_c('span',{staticStyle:{"color":"red"}},[_vm._v("-"+_vm._s(item.money.toFixed(2)))]):_c('span',{staticStyle:{"color":"#54B61C"}},[_vm._v("+"+_vm._s(Number((item.bonus_money-item.money).toFixed(2))))])])])})],2)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }