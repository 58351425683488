<script>
    import { tz_record } from "@/api/beijing28";//投注记录
    
    export default {
        data() {
            return {
                list: [],
                recordbool: false,
                type: null,
                roomId: null,
                startTime: null,
                endTime: null,
                isProfit: null,
                loading_data2: true,
                page: 1,
                pageSize: 20,
                totalPage: 0
            };
        },
        directives: {
            'infinite-scroll': app.Mint.InfiniteScroll,
        },
        methods: {
            left_click() {
                let hisNum = parseInt(window.history.length);
                if (hisNum == 1) {
                    this.$router.push("/home");
                } else {
                    this.$router.go(-1);
                }
            },
            //记录查询
            commit() {
                tz_record(
                    this.type,
                    this.roomId,
                    this.startTime,
                    this.endTime,
                    this.isProfit,
                    this.page,
                    this.pageSize
                ).then(result => {
                    if (result.data.code == "SUCCESS") {
                        this.totalPage = result.data.result.totalPage;
                        for (var i = 0; i <= result.data.result.list.length - 1; i++) {
                            let b = result.data.result.list[i].bonus_money.indexOf('.');
                            result.data.result.list[i].bonus_money = result.data.result.list[i].bonus_money.substring(0,b+3);
                            result.data.result.list[i].bonus_money = Number(result.data.result.list[i].bonus_money);

                            b = result.data.result.list[i].money.indexOf('.');
                            result.data.result.list[i].money = result.data.result.list[i].money.substring(0,b+3);
                            result.data.result.list[i].money = Number(result.data.result.list[i].money);
                            this.list.push(result.data.result.list[i]);
                        }
                    } else {
                        app.Mint.Toast(result.data.msg);
                    }
                    this.loading_data2 = false;
                });
            },
            //分页
            loadMore_data() {
                if (this.page >= this.totalPage) {
                    return false;
                } else {
                    this.page++;
                    this.commit();
                }
            }
        },
        mounted() {
            this.CustomScroll(this.$refs.tradeHistory.id);
            this.type = this.$route.query.type;
            this.roomId = this.$route.query.room;
            this.commit();
        }
    };
</script>

<template>
    <div class="query_record">
        <div v-if="recordbool">
            <div class="main_image"></div>
            <span>{{ getWord('no_result6') }}</span>
            <span>{{ getWord('start_game2') }}</span>
        </div>
        <div v-if="! recordbool" id='trade-history' ref='tradeHistory'>
            <table class="main_table" cellspacing="0" border="0" v-infinite-scroll="loadMore_data" infinite-scroll-disabled="loading_data2" infinite-scroll-distance="50">
                <tr>
                    <th width="15%">{{ getWord('drawing_number') }}</th>
                    <th width="55%">{{ getWord('content') }}</th>
                    <th width="15%">{{ getWord('amount_of_betting') }}</th>
                    <th width="15%">{{ getWord('result') }}</th>
                </tr>                
                <tr v-if="list.length===0">
                    <td class="tip" colspan="4">{{ getWord('no_result6') }}</td>
                </tr>
                <tr v-for="(item,i) in list" :key="i">
                    <td>
                        {{item.issue }}
                    </td>
                    <td v-if="item.lottery_class == 'WEI_LIAN'
                     || item.lottery_class == 'ALLNOTIN' 
                     || item.lottery_class == 'LIANMA' 
                     || item.lottery_class == 'ANIMAL_LIAN' 
                     || item.lottery_class == 'TEMA_HE_ANIMAL' 
                     || item.lottery_class == 'QIANER' 
                     || item.lottery_class == 'QIANSAN' 
                     || item.lottery_class == 'ZUER' 
                     || item.lottery_class == 'ZUSAN' 
                     || item.lottery_class.indexOf('LIANMA')>-1 
                     || item.lottery_class == 'ERZIBAISHIWEI' 
                     || item.lottery_class == 'ERZIBAIGEWEI' 
                     || item.lottery_class == 'ERZISHIGEWEI' 
                     || item.lottery_key.indexOf('LIAN_REN_')>-1  
                     || item.lottery_class.indexOf('ZUXUANSAN')>-1 
                     || item.lottery_class.indexOf('ZUXUANLIU')>-1"
                    >
                        <span>{{item.bet_name}}</span>
                        <span class="number">{{item.bet_number}}</span>
                        <span class="date">{{ new Date(item.created_at*1000).toLocaleString().split(' ')[0] }} {{ new Date(item.created_at*1000).toTimeString().split(' ')[0] }}</span>
                    <td v-else>
                        <span>{{item.bet_name}}</span>
                        <span class="number">{{ isNaN(item.bet_number)?'':item.bet_number }}</span>
                        <span class="date">{{ new Date(item.created_at*1000).toLocaleString().split(' ')[0] }} {{ new Date(item.created_at*1000).toTimeString().split(' ')[0] }}</span>
                    </td>
                    <td>{{item.money.toFixed(2)}}</td>
                    <td>
                        <span v-if="item.status==1" style="color:rgba(102,102,102,1);">{{ getWord('unsettled_account') }}</span>
                        <span v-else-if="item.status==2&&item.bonus_money=='0'" style="color:red;">-{{item.money.toFixed(2)}}</span>
                        <span v-else style="color:#54B61C;">+{{Number((item.bonus_money-item.money).toFixed(2))}}</span>
                    </td>
                </tr>
            </table>
        </div>
    </div>    
</template>

<style lang="scss" scoped type="text/css">
    .query_record {
        background:#F3F3F4;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .main_image {
        float: left;
        height: 2.57rem;
        width: 2.12rem;
        background-image: url('/static/assets/images2/wjl.png');
        background-size: 100%;
        margin: 2.4rem 0 0 2.8rem;
        background-repeat: no-repeat;
    }

    #trade-history {
        height: 100%;
        overflow: auto;
    }

    .main_table {
        float: left;
        width: 100%;
        font-size: 0.3rem;
        text-align: center;
        background:#FFF;
        border:none;

        tr{
            color: #999999;

            th{
                font-size: 0.24rem;
                font-weight:normal;
                text-align: left;
                background:#EAEAEA;
                padding: .15rem .1rem;
                }

            td{
                text-align: left;
                color:#252525;
                font-size:0.18rem;
                border-bottom:1px solid #D9D9D9;
                padding: 0 .1rem;

                .number {
                    display: block;
                    color: #FF0F0F;
                }

                .date {
                    display: block;
                }

                &.tip {
                    background-color: transparent;
                    color: #cccccc;
                    font-size: .24rem;
                    text-align: center;
                }

                &:first-child {
                    padding: 0.3rem .1rem;
                }

                &:nth-child(2) {
                    
                    span {

                        &:last-child {
                            color:#999999;
                        }
                    }
                }
            }
        }
    }
</style>


